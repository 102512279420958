import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/sales', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSalesWithoutGuide(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/sales/without-guide', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSalesWithoutGuideTotal(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/sales/without-guide-total')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelSale(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/sale/${id}/cancel`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSale(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/sale/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
