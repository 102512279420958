import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function saleHandler() {
  // Use toast
  const toast = useToast()

  const refSaleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'sale_date', label: 'Fecha Venta', sortable: false },
    { key: 'sale_code', label: 'Codigo', sortable: false },
    // { key: 'client', label: 'Contacto', sortable: false },
    { key: 'business_name', label: 'Cliente', sortable: false },
    { key: 'subtotal', label: 'Subtotal', sortable: false },
    { key: 'igv', label: 'I.G.V(%)', sortable: false },
    { key: 'total', label: 'Total', sortable: false },
    { key: 'commission', label: 'Comisón(%)', sortable: false },
    { key: 'quotation_status', label: 'Estado', sortable: false },
    { key: 'actions',label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalSales = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')

  const refetchData = () => {
    refSaleListTable.value.refresh()
  }

  watch([currentPage, searchQuery], () => {
    refetchData()
  })

  const fetchSales = (ctx, callback) => {
    store
      .dispatch('app-sales/fetchSales', {
        searchText: searchQuery.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalSales.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching guides list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveStatusVariant = status => {
    if (status === 'VENTA') return 'success'
    if (status === 'ANULADO') return 'danger'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchSales,
    tableColumns,
    perPage,
    currentPage,
    totalSales,
    searchQuery,
    refSaleListTable,
    refetchData,
    resolveStatusVariant,
  }
}
